<template>
  <section>
    <loader v-if="loading" />
    <v-card :dark="$dark.get()" min-height="90vh">
      <v-card-title class="header">
        Купоны
        <v-btn class="coupon-btn" @click="modal = true"
          >Добавить купон</v-btn
        ></v-card-title
      >
      <loader v-if="loading"></loader>
      <v-card-text v-else class="main-content">
        <CouponCard
          v-for="card in COUPONS"
          :key="card.id"
          :coupon="card"
          @delete="deleteCoupon(card.id)"
          @edit="openModal(card)"
        />
      </v-card-text>
    </v-card>
    <!-- Окно с полной информацией -->
    <v-dialog v-model="modal" activator="parent" width="60%">
      <v-card class="modal">
        <v-card-title class="modal_title"
          >{{ editMode ? "Изменение" : "Создание" }} купона</v-card-title
        >
        <v-card-text>
          <v-form ref="coupon">
            <v-text-field
              v-model="coupon.code"
              :rules="[$validate.required]"
              color="#5D4BD0"
              label="Код купона"
              type="number"
              required
            ></v-text-field>
            <v-text-field
              v-model="coupon.discountAmount"
              :rules="[$validate.required]"
              color="#5D4BD0"
              label="Сумма скидки"
              type="number"
              required
            ></v-text-field>
            <v-text-field
              v-model="coupon.secretWord"
              color="#5D4BD0"
              :rules="[$validate.required]"
              label="Секретное слово"
              required
            ></v-text-field>

            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="coupon.startDate"
                  label="Начало действия"
                  prepend-icon="mdi-calendar"
                  color="#5D4BD0"
                  readonly
                  v-bind="attrs"
                  hide-details
                  :rules="[$validate.required]"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="coupon.startDate"
                no-title
                scrollable
                @change="menu = false"
              ></v-date-picker>
            </v-menu>

            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="coupon.endDate"
                  label="Окончание действия"
                  prepend-icon="mdi-calendar"
                  color="#5D4BD0"
                  readonly
                  v-bind="attrs"
                  hide-details
                  :rules="[$validate.required]"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="coupon.endDate"
                no-title
                scrollable
                :min="coupon.startDate"
                @change="menu2 = false"
              ></v-date-picker>
            </v-menu>

            <v-checkbox
              v-model="coupon.active"
              color="#5D4BD0"
              label="Активный"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions class="modal_actions">
          <v-btn v-if="editMode" color="#5D4BD0" dark @click="editCoupon()"
            >Изменить</v-btn
          >
          <v-btn v-else color="#5D4BD0" dark @click="createCoupon()"
            >Создать</v-btn
          >
          <v-btn class="coupon-btn" @click="modal = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Подтвердение удаления -->
    <delete-dialog
      :show="show"
      @close-modal="show = !show"
      @delete-modal="deleteCoupon()"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import showMessage from "@/Functions/message";
import DeleteDialog from "@/components/DeleteDialog";
import CouponCard from "@/components/Coupons/CouponCard";
import loader from "@/components/Loader";

export default {
  components: {
    loader,
    DeleteDialog,
    CouponCard,
  },
  data() {
    return {
      coupon: {
        active: true,
        secretWord: null,
        code: null,
        discountAmount: null,
        startDate: "",
        endDate: "",
      },
      modal: false,
      editMode: false,
      deletedId: 0,
      show: false,
      loading: false,
      menu: false,
      menu2: false,
    };
  },
  computed: {
    ...mapGetters({
      COUPONS: "Coupons/STATE",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.editMode = false;
        this.coupon = {
          active: true,
          secretWord: null,
          code: null,
          discountAmount: null,
          startDate: "",
          endDate: "",
        };
        this.$refs.coupon.resetValidation();
      }
    },
  },
  mounted() {
    this.setValues();
  },
  methods: {
    ...mapActions({
      GET_ALL_COUPONS: "Coupons/GET_ALL_COUPONS",
      ADD_COUPON: "Coupons/ADD_COUPON",
      DELETE_COUPON: "Coupons/DELETE_COUPON",
      EDIT_COUPON: "Coupons/EDIT_COUPON",
    }),
    //Подгружает все тэги
    async setValues() {
      this.loading = true;
      await this.GET_ALL_COUPONS();
      this.loading = false;
    },
    async createCoupon() {
      if (!this.$refs.coupon.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      this.loading = true;
      const response = await this.ADD_COUPON(this.coupon);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Купон добавлен", true);
        await this.setValues();
      }
      this.modal = false;
      this.loading = false;
    },
    async editCoupon() {
      if (!this.$refs.coupon.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      this.loading = true;
      const response = await this.EDIT_COUPON(this.coupon);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Купон изменен", true);
        await this.setValues();
      }
      this.modal = false;
      this.loading = false;
    },
    openModal(coupon) {
      this.editMode = true;
      this.coupon = JSON.parse(JSON.stringify(coupon));
      this.modal = true;
    },
    async deleteCoupon(id) {
      if (id) {
        this.deletedId = id;
      } else {
        this.loading = true;
        let response = await this.DELETE_COUPON(this.deletedId);
        if (response?.type === "error") {
          showMessage(response.data.message);
        } else {
          showMessage("Купон удален", true);
        }
        this.deletedId = 0;
        await this.setValues();
        this.loading = false;
      }
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss">
.main-content {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.coupon-btn {
  border: 1px solid #5d4bd0;
  color: #5d4bd0 !important;
  border-radius: 7px;
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_title {
    justify-content: center;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  &_actions {
    justify-content: right;
  }
  &_bold {
    font-weight: 800;
  }
  &_images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_title {
    width: 90%;
    h1 {
      margin-left: 10%;
    }
  }
}
.rate-stars {
  button {
    padding: 0 !important;
  }
  width: 90px;
}
</style>
