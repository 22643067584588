<template>
  <v-card :dark="$dark.get()" class="coupon">
    <v-card-text class="coupon_content">
      <div class="coupon_text">
        <p class="coupon_title">Слово:</p>
        <p class="coupon_data">{{ coupon.secretWord }}</p>
      </div>
      <div class="coupon_text">
        <p class="coupon_title">Код:</p>
        <p class="coupon_data">{{ coupon.code }}</p>
      </div>
      <div class="coupon_text">
        <p class="coupon_title">Сумма скидки:</p>
        <p class="coupon_data">{{ coupon.discountAmount }}</p>
      </div>
      <div class="coupon_text">
        <p class="coupon_title">Дата начала:</p>
        <p class="coupon_data">{{ coupon.startDate }}</p>
      </div>
      <div class="coupon_text">
        <p class="coupon_title">Дата окончания:</p>
        <p class="coupon_data">{{ coupon.endDate }}</p>
      </div>
    </v-card-text>
    <v-card-actions class="coupon_actions">
      <v-switch
        :input-value="coupon.active"
        color="#E93E7B"
        inset
        hide-details
        readonly
        class="switch mt-0"
        label="Активный"
      ></v-switch>
      <div>
        <v-btn icon color="#5D4BD0" @click="$emit('edit')"
          ><v-icon>mdi-pencil-circle</v-icon>
        </v-btn>
        <v-btn icon color="error" @click="$emit('delete')"
          ><v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CouponCard",
  props: {
    coupon: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.coupon {
  border: 1px solid #5d4bd0;
  border-radius: 20px;
  width: calc(25% - 20px);
  @media screen and (max-width: 500px) {
    width: calc(100% - 20px);
  }
  &_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &_text {
    display: flex;
    gap: 5px;
    background-color: #eeeeee;
    border-radius: 8px;
    padding: 5px 15px;
  }
  &_title {
    margin-bottom: 0;
  }
  &_data {
    margin-bottom: 0;
    font-weight: bold;
    color: black;
  }
  &_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 16px;
  }
}
</style>
